import { message, notification } from 'antd'
import R from 'assets'
import { LightBulbIcon } from 'common/components/Icons'
import BlueIconComp from 'common/components/Icons/BlueIconComp'
import { ADMIN_ROUTER_PATH } from 'common/config'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'
import { requestAnOrder, sendOrder } from './api/ApiOrder'
import * as S from './styled'
import { useTranslation } from 'react-i18next'

const styleIcon = { fontSize: '35px', color: 'white' }

const BillOrder: React.FC = () => {
  const [data, setData] = useState<any>({})
  const location: any = useLocation()
  const { t } = useTranslation()

  useEffect(() => {
    console.log('location.state: ', location.state)
    if (location.state.own_level) {
      getData(location.state.own_level)
    } else {
      getData(location.state.first_level)
    }
  }, [])

  const getData = async (key: any) => {
    try {
      const res = await requestAnOrder(key)
      setData(res.data)
    } catch (error) {
      history.goBack()
      // message.error('Có lỗi xảy ra')
    }
  }

  const handleClickBtn = async () => {
    try {
      const res = await sendOrder(data?._id)
      if (res.data?.status === 'Success') {
        notification.success({
          message: t('order'),
          description: t('send_order_success'),
        })
        history.push(ADMIN_ROUTER_PATH.ORDER)
      }
    } catch (error) {
      // message.error('Không đủ số dư, vui lòng nạp tiền vào tài khoản')
    }
  }

  return (
    <S.WrapContainer>
      <S.BillContainer>
        <S.Title>
          <BlueIconComp icon={<LightBulbIcon style={styleIcon} />} />
          {t('order_unit')}
        </S.Title>
        <div style={{ marginTop: '1rem' }}>
          {t('time_reciver')}：
          {data?.created_at &&
            moment(data?.created_at).format('DD/MM/YYYY HH:mm:ss')}
        </div>
        <div>
          {t('code')}：{data?.product?.name}
        </div>
        <b>{data?.meta?.level.commission_percent ? data?.meta?.level.commission_percent : 0}%</b>
        <S.ImageBlock>
          <img
            alt="gif"
            src={data?.product?.image ? process.env.REACT_APP_IMG_URL + data?.product?.image : R.images.product_default}
            crossOrigin="anonymous"
          />
        </S.ImageBlock>
        <div className="info">
          {t('status_order')}: <span className="content">
            {data?.status === 'Waiting' && 'Chưa phân phối'}
            {data?.status === 'Pending' && 'Chờ xử lý'}
            {data?.status === 'Processing' && 'Đang xử lý'}
            {data?.status === 'Success' && 'Hoàn thành'}
            {data?.status === 'Frozen' && 'Đóng băng'}
          </span>
        </div>
        <div className="info">
          {t('total_order')}:
          <span className="content">{formatPrice(data?.meta?.value)} VNĐ</span>
        </div>
        <div className="info">
          {t('profit')}:
          <span className="content">
             {formatPrice(data?.meta?.commission)} VNĐ
          </span>
        </div>
        <div className="info">
          {t('total_refund')}:
          <span className="content">
            {' '}
             {formatPrice(data?.meta?.value + data?.meta?.commission)} VNĐ
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <S.StartBtn
            type="primary"
            size="large"
            onClick={() => {
              handleClickBtn()
            }}
          >
            {t('send_order')}
          </S.StartBtn>
        </div>
      </S.BillContainer>
    </S.WrapContainer>
  )
}
export default BillOrder
